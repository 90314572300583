import React, {useContext} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

    const NavBar = () => {
    const { token, logout, userType } = useContext(AuthContext);

    const navigate = useNavigate();

    const handleLogout = async () => {
        // setToken('');
        // localStorage.removeItem('token');
        logout();
        navigate('/login');
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">Houdini Plumbing Referrals</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/register/individual">Register Individual</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/register/business">Register Business</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/login">Login</Link>
                        </li>
                        <li className="nav-item">
                            <button className="btn btn-link nav-link" onClick={handleLogout}>Logout</button>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/individual-forms">Individual User Form</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/business-forms">Business User Form</Link>
                        </li> */}
                        {!token ? (
                        <>
                            <li className="nav-item">
                            <Link className="nav-link" to="/register/individual">Register Individual</Link>
                            </li>
                            <li className="nav-item">
                            <Link className="nav-link" to="/register/business">Register Business</Link>
                            </li>
                            <li className="nav-item">
                            <Link className="nav-link" to="/login">Login</Link>
                            </li>
                        </>
                        ) : (
                        <>
                            <li className="nav-item">
                            <button className="btn btn-link nav-link" onClick={handleLogout}>Logout</button>
                            </li>
                            {userType === 'individual' && (
                            <li className="nav-item">
                                <Link className="nav-link" to="/individual-forms">Individual User Form</Link>
                            </li>
                            )}
                            {userType === 'business' && (
                            <li className="nav-item">
                                <Link className="nav-link" to="/business-forms">Business User Form</Link>
                            </li>
                            )}
                        </>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
