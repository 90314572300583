import React, { useState } from 'react';

const ResetPasswordRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleResetRequest = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/reset-password-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            if (response.ok) {
                setMessage('Password reset link sent to your email.');
            } else {
                setMessage('Failed to send reset link.');
            }
        } catch (error) {
            setMessage('Failed to send reset link.');
        }
    };

    return (
        <div className="container">
            <h2 className="mt-4">Reset Password</h2>
            {message && <div className="alert alert-info">{message}</div>}
            <form onSubmit={handleResetRequest}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Send Reset Link</button>
            </form>
        </div>
    );
};

export default ResetPasswordRequest;
