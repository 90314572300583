import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});

console.log(`API Base URL: ${process.env.REACT_APP_API_BASE_URL}`);

export const registerIndividual = (data) => api.post('/users/register/individual', data);
export const registerBusiness = (data) => api.post('/users/register/business', data);
export const login = (data) => api.post('/users/login', data);
export const submitIndividualForm = async (formData, token) => {
    console.log('formData:', formData);

    const response = await axios.post(`${api.baseURL}/individual-forms`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

    if (!response.ok) {
        throw new Error('Form submission failed');
    }

    return response.json();
};
export const submitBusinessForm = async (formData, token) => {
    const response = await axios.post(`${api.baseURL}/business-forms`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

    if (!response.ok) {
        throw new Error('Form submission failed');
    }

    return response.json();
};
