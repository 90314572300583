// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [userType, setUserType] = useState(localStorage.getItem('userType') || '');

  console.log('userType:', userType);

  useEffect(() => {
    if (token) {
      try {
        jwtDecode(token);
      } catch (error) {
        console.error('Invalid token', error);
        setToken('');
        localStorage.removeItem('token');
      }
    }
  }, [token]);

  const login = (newToken, newUserType) => {
    console.log('logging in');
    localStorage.setItem('token', newToken);
    localStorage.setItem('userType', newUserType);
    setToken(newToken);
    setUserType(newUserType);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    setToken('');
    setUserType('');
  };

  return (
    <AuthContext.Provider value={{ token, userType, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
