import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const IndividualForm = () => {
    
    const [formData, setFormData] = useState({
        userId: '',
        name: '',
        phoneNumber: '',
        emailAddress: '',
        address: '',
        measurement: '',
        photoOfUnit: null,
        photoOfSwitchboard: null,
        relocation: '',
        relocationDistance: '',
        waterReady: '',
        groundCondition: '',
        oldUnitAccessible: '',
        clearAccess: '',
        quotePrice: '',
        referral: '',
    });
    const [error, setError] = useState('');
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else {
            // Decode the token to get the userId
            const decodedToken = jwtDecode(token);
            setFormData((prevFormData) => ({
                ...prevFormData,
                userId: decodedToken.id, // Assuming the userId is stored in the 'id' field
            }));
        }
    }, [token, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({
            ...formData,
            [name]: files[0],
        });
    };

    const uploadFile = async (file) => {
        const form = new FormData();
        form.append('file', file);
    
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/media`, form, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
    
        return response.data.doc.id; // Return the uploaded file ID
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Upload files first and get their IDs
            const photoOfUnitId = await uploadFile(formData.photoOfUnit);
            const photoOfSwitchboardId = await uploadFile(formData.photoOfSwitchboard);

            // Prepare the form data with the uploaded file IDs
            const submissionData = {
                ...formData,
                photoOfUnit: photoOfUnitId,
                photoOfSwitchboard: photoOfSwitchboardId,
            };

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/individual-forms`, submissionData, {
                headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                },
            });
            alert('Form submitted successfully');
            navigate('/submissions');
        } catch (error) {
            console.error('Form submission error', error);
            setError('Form submission failed');
        }
    };

    return (
        <div className="container mt-5">
            <div style={{"alignItems": "right"}}><a href="/submissions">View Submissions</a></div>
            <h2>Individual User Form</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input type="tel" className="form-control" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="emailAddress">Email Address</label>
                    <input type="email" className="form-control" id="emailAddress" name="emailAddress" value={formData.emailAddress} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input type="text" className="form-control" id="address" name="address" value={formData.address} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="measurement">Measurement</label>
                    <input type="text" className="form-control" id="measurement" name="measurement" value={formData.measurement} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="photoOfUnit">Photo of the Unit</label>
                    <input type="file" className="form-control-file" id="photoOfUnit" name="photoOfUnit" onChange={handleFileChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="photoOfSwitchboard">Photo of the Switchboard</label>
                    <input type="file" className="form-control-file" id="photoOfSwitchboard" name="photoOfSwitchboard" onChange={handleFileChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="relocation">Relocation?</label>
                    <select className="form-control" id="relocation" name="relocation" value={formData.relocation} onChange={handleChange} required>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                {formData.relocation === 'yes' && (
                    <div className="form-group">
                        <label htmlFor="relocationDistance">If yes, how far?</label>
                        <input type="text" className="form-control" id="relocationDistance" name="relocationDistance" value={formData.relocationDistance} onChange={handleChange} required />
                    </div>
                )}
                <div className="form-group">
                    <label htmlFor="waterReady">Is the cold and hot water ready on the new location and accessible?</label>
                    <select className="form-control" id="waterReady" name="waterReady" value={formData.waterReady} onChange={handleChange} required>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="groundCondition">Is the ground concrete, leveled and flat?</label>
                    <select className="form-control" id="groundCondition" name="groundCondition" value={formData.groundCondition} onChange={handleChange} required>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="oldUnitAccessible">Is the old unit accessible?</label>
                    <select className="form-control" id="oldUnitAccessible" name="oldUnitAccessible" value={formData.oldUnitAccessible} onChange={handleChange} required>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="clearAccess">Do we have clear and clean access in the work area?</label>
                    <select className="form-control" id="clearAccess" name="clearAccess" value={formData.clearAccess} onChange={handleChange} required>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="quotePrice">Quote Price</label>
                    <input type="text" className="form-control" id="quotePrice" name="quotePrice" value={formData.quotePrice} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="referral">Who referred you?</label>
                    <input type="text" className="form-control" id="referral" name="referral" value={formData.referral} onChange={handleChange} required />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
            </form>
        </div>
    );
};

export default IndividualForm;
