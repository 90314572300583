import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';

const UserSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token, userType } = useContext(AuthContext);

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const endpoint = userType === 'business' ? 'business-forms' : 'individual-forms';
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const jwt = JSON.parse(atob(token.split('.')[1]));

        const userId = jwt.id;

        const returnedSubmissions = response.data.docs;

        const UserSubmissions = returnedSubmissions.filter(submission => submission.userId && submission.userId.id === userId);

        setSubmissions(UserSubmissions);
      } catch (error) {
        console.error('Error fetching submissions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, [token, userType]);

  if (loading) {
    return <p>Loading submissions...</p>;
  }

  return (
    <div>
      <h1>Your Submissions</h1>
      {submissions.length === 0 ? (
        <p>No submissions found.</p>
      ) : (
        <Accordion defaultActiveKey="0">
          {submissions.map((submission, index) => (
            <Accordion.Item eventKey={index.toString()} key={submission.id}>
              <Accordion.Header><strong>Address:</strong> &nbsp; {submission.address} &emsp; <strong><em>{submission.status}</em></strong></Accordion.Header>
              <Accordion.Body>
              {userType === 'individual' ? (
                  <>
                    <p><strong>Name: {submission.name}</strong></p>
                    <p><strong>Phone Number: {submission.phoneNumber}</strong></p>
                    <p><strong>Email Address: {submission.emailAddress}</strong></p>
                    <p><strong>Address: {submission.address}</strong></p>
                    <p><strong>Measurement: {submission.measurement}</strong></p>
                    {submission.photoOfUnit && (
                      <img src={`${process.env.REACT_APP_BASE_URL}/media/${submission.photoOfUnit.filename}`} alt="Photo of Unit" style={{ maxWidth: '50%' }} />
                    )}
                    {submission.photoOfSwitchboard && (
                      <img src={`${process.env.REACT_APP_BASE_URL}/media/${submission.photoOfSwitchboard.filename}`} alt="Photo of Switchboard" style={{ maxWidth: '50%' }} />
                    )}
                    <p><strong>Relocation:</strong> {submission.relocation ? 'Yes' : 'No'}</p>
                    {submission.relocation && (
                      <p><strong>Relocation Distance:</strong> {submission.relocationDistance}</p>
                    )}
                    <p><strong>Cold and Hot Water Ready:</strong> {submission.waterReady ? 'Yes' : 'No'}</p>
                    <p><strong>Ground Condition:</strong> {submission.groundCondition ? 'Yes' : 'No'}</p>
                    <p><strong>Old Unit Accessible:</strong> {submission.oldUnitAccessible ? 'Yes' : 'No'}</p>
                    <p><strong>Clear Access:</strong> {submission.clearAccess ? 'Yes' : 'No'}</p>                    
                    <p><strong>Quote Price:</strong> {submission.quotePrice}</p>
                    <p><strong>Referral:</strong> {submission.referral}</p>
                    <p><strong>Status:</strong> {submission.status}</p>
                  </>
                ) : (
                  <>
                    <p><strong>Business Name: {submission.businessName}</strong></p>
                    <p><strong>Contact Person: {submission.contactPerson}</strong></p>
                    <p><strong>Phone Number: {submission.phoneNumber}</strong></p>
                    <p><strong>Email Address: {submission.emailAddress}</strong></p>
                    <p><strong>Address: {submission.address}</strong></p>
                    <p><strong>Measurement: {submission.measurement}</strong></p>
                    {submission.photoOfUnit && (
                      <img src={`${process.env.REACT_APP_BASE_URL}/media/${submission.photoOfUnit.filename}`} alt="Photo of Unit" style={{ maxWidth: '50%' }} />
                    )}
                    {submission.photoOfSwitchboard && (
                      <img src={`${process.env.REACT_APP_BASE_URL}/media/${submission.photoOfSwitchboard.filename}`} alt="Photo of Switchboard" style={{ maxWidth: '50%' }} />
                    )}
                    <p><strong>Relocation:</strong> {submission.relocation ? 'Yes' : 'No'}</p>
                    {submission.relocation && (
                      <p><strong>Relocation Distance:</strong> {submission.relocationDistance}</p>
                    )}
                    <p><strong>Cold and Hot Water Ready:</strong> {submission.waterReady ? 'Yes' : 'No'}</p>
                    <p><strong>Ground Condition:</strong> {submission.groundCondition ? 'Yes' : 'No'}</p>
                    <p><strong>Old Unit Accessible:</strong> {submission.oldUnitAccessible ? 'Yes' : 'No'}</p>
                    <p><strong>Clear Access:</strong> {submission.clearAccess ? 'Yes' : 'No'}</p>
                    <p><strong>Status:</strong> {submission.status}</p>
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default UserSubmissions;
