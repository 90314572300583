// src/App.js

import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import RegisterIndividual from './components/RegisterIndividual';
import RegisterBusiness from './components/RegisterBusiness';
import Login from './components/Login';
import IndividualForm from './components/IndividualForm';
import BusinessForm from './components/BusinessForm';
import UserSubmissions from './components/UserSubmissions';
import NavBar from './components/NavBar';
import ResetPasswordRequest from './components/ResetPasswordRequest';
import ResetPassword from './components/ResetPassword';
import { AuthProvider, AuthContext } from './context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const AppRoutes = () => {
  const { token, userType } = useContext(AuthContext);

  return (
        <Routes>        
            <Route path="/register/individual" element={<RegisterIndividual />} />
            <Route path="/register/business" element={<RegisterBusiness />} />
            <Route path="/login" element={<Login />} />
            {userType === 'individual' && <Route path="/individual-forms" element={<IndividualForm />} />}
            {userType === 'business' && <Route path="/business-forms" element={<BusinessForm />} />}
            <Route path="/submissions" element={<UserSubmissions />} />
            <Route path="/reset-password" element={<ResetPasswordRequest />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/" element={token ? <Navigate to={userType === 'individual' ? "/individual-forms" : "/business-forms"} /> : <Navigate to="/login" />} />
        </Routes>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <NavBar />
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
};

export default App;
